import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonLink } from '@naf/button-link';
import { spacing, breakpoints, fontStyle } from '@naf/theme';
import { GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { NumberInCircle } from '@naf/number';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { LinkListType } from '../../../../types/CategoryAppType';
import { InternalLinkType } from '../../../../types/internalLinkType';

const ExternalTargetButtonLink = ({
  title,
  externalTarget,
  className,
}: {
  title: string;
  externalTarget: {
    href: string;
    blank: boolean;
  };
  className?: string;
}) => {
  const { href, blank } = externalTarget;
  return (
    <ButtonLink
      text={title || 'Les mer'}
      href={href}
      target={blank ? '_blank' : undefined}
      rel={blank ? 'noopener noreferrer' : undefined}
      className={className}
    />
  );
};

const InternalReferenceButtonLink = ({
  title,
  internalReference,
  className,
}: {
  title?: string;
  internalReference: InternalLinkType;
  className?: string;
}) => {
  const target = useDocumentUrlWithNode(internalReference);
  if (!target) return null;
  return <ButtonLink as={Link} text={title || 'Les mer'} to={target} className={className} />;
};

export const NumberedLinkCard = ({ entry, index }: { entry: LinkListType; index?: number }) => (
  <StyledGridCol s="12" m="6" l="4" xl="4" key={`${entry.title}`}>
    <CardWrapper className="CardWrapper">
      {index && <NumberWrapper count={index} size="large" />}
      {entry.title && <StyledHeader variant={TextVariant.Header3}>{entry.title}</StyledHeader>}
      {entry.ingress && <StyledText variant={TextVariant.BodyText}>{entry.ingress}</StyledText>}
      {entry.internalLink?.data?.reference && (
        <InternalReferenceButtonLink
          title={entry.internalLink?.data?.title}
          internalReference={entry.internalLink?.data?.reference}
        />
      )}
      {entry.externalLink?.data?.href && !entry.internalLink?.data?.reference && (
        <ExternalTargetButtonLink title={entry.externalLink?.data?.title} externalTarget={entry.externalLink?.data} />
      )}
    </CardWrapper>
  </StyledGridCol>
);

export const StyledGridCol = styled(GridCol)`
  display: flex;
  &:last-child {
    .CardWrapper {
      @media (max-width: ${breakpoints.s}) {
        border-left: none;
      }
    }
  }
`;

const StyledHeader = styled(Text)`
  margin-bottom: 0px;
  margin-top: ${spacing.space8};
  @media (max-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space8};
    ${fontStyle.article.articleText};
  }
`;

const CardWrapper = styled.div`
  padding-bottom: ${spacing.space48};
  position: relative;
  @media (max-width: ${breakpoints.s}) {
    padding-left: ${spacing.space32};
    margin-left: ${spacing.space12};
    border-left: 1px dashed ${({ theme }) => (theme.typography ? theme.typography.defaultText : '#1A1A1A')};
  }
`;

const NumberWrapper = styled(NumberInCircle)`
  background-color: ${({ theme }) => (theme.background ? theme.background.default : '#fff')};
  @media (max-width: ${breakpoints.s}) {
    position: absolute;
    left: -12px;
    width: 24px;
    height: 24px;
  }
`;
