import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text, TextVariant } from '@naf/text';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@naf/button-link';
import isEmpty from 'lodash/isEmpty';
import useSelector from '../../../redux/typedHooks';
import { actions as BenefitsActions } from '../../../redux/modules/benefits';
import BenefitsList from '../../../components/benefits-list/BenefitsList';
import { ReadMoreWrapper, SectionCol } from '../Styles';

export type BenefitsByTagProps = {
  tag: string;
};

export const BenefitsByTag = ({ tag }: BenefitsByTagProps) => {
  const dispatch = useDispatch();

  const benefits = useSelector((state) => state.benefits);
  const [isBenefitsFetched, setIsBenfitsFetched] = useState(false);

  useEffect(() => {
    if (tag && !isBenefitsFetched) {
      dispatch(BenefitsActions.getBenefits.request(`take=4&Tags=${tag}`));
      if (!benefits.errorState) setIsBenfitsFetched(true);
    }
  }, [dispatch, benefits.errorState, tag, isBenefitsFetched]);

  if (benefits?.benefitsList && !isEmpty(benefits.benefitsList) && !benefits.isUpdating && isBenefitsFetched) {
    return (
      <SectionCol s="12" m="12" l="12" xl="12">
        <ReadMoreWrapper>
          <Text tag="h2" variant={TextVariant.Header2}>
            Medlemsfordeler
          </Text>
          <ButtonLink
            as={Link}
            to={`medlemskap/medlemsfordeler?tags=${benefits.selectedTags?.length && benefits.selectedTags[0].slug}`}
            text={`Se alle (${benefits.total})`}
          />
        </ReadMoreWrapper>
        <BenefitsList benefitItems={benefits.benefitsList} />
      </SectionCol>
    );
  }
  return null;
};
